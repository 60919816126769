import React, { useState, useEffect } from "react";
import axios from "axios";

import "./footer.scss";
const bbb = require("./bbb1.png");
const everify = require("./e-verify.png");
const ucedc = require("./ucedc.png");
const whiteTick = require("./white-tick.png");

function Footer() {
  const [displayMessage, setDisplayMessage] = useState("none");

  useEffect(() => {}, [displayMessage]);

  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [rooms, setRooms] = useState("");
  const [crew, setCrew] = useState("");
  const [date, setDate] = useState("");
  const [zipFrom, setZipFrom] = useState("");
  const [zipTo, setZipTo] = useState("");
  const [details, setDetails] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    let tgMessage = `<b>New request: </b>
    full name: ${fullName}
    email: ${email}
    phone: ${phone}
    rooms: ${rooms}
    date: ${date}
    zipFrom: ${zipFrom}
    zipTo: ${zipTo}
    details: ${details}
    title: ${title}
    message: ${message}
    `;
    axios
      .post(process.env.REACT_APP_URI_API, {
        chat_id: process.env.REACT_APP_CHAT_ID,
        parse_mode: "html",
        text: tgMessage,
      })
      .then((res) => {
        if (displayMessage == "none") {
          setDisplayMessage("flex");
        } else {
          setDisplayMessage("none");
        }
        // console.log("DONE");
        setFullName("");
        setPhone("");
        setEmail("");
        setRooms("");
        setDate("");
        setCrew("");
        setZipFrom("");
        setZipTo("");
        setTitle("");
        setMessage("");
        setDetails("");
      })
      .catch((err) => {
        alert("Something went wrong!");
      });
  };
  return (
    <>
      <section className="section footer-section">
        <div className="container-1320 footer-container">
          <div className="footer-left-div">
            <h3 className="footer-title">Contacts:</h3>
            <p className="footer-name footer-contact">
              {/* <img classname="email" src={email} /> */}
              STARS'N'STRIPES MOVERS CO.
              <br />
            </p>
            <p className="footer-contact footer-telephone">
              {/* <img classname="email" src={email} /> */}
              +1(213)565-1301
            </p>
            <p className="footer-email footer-contact">
              starsnstripesmovers@gmail.com
            </p>{" "}
            <p className="footer-address-option footer-contact">
              1545 Pico Blvd, Ste 102,
            </p>
            <span className="footer-address footer-contact">
              Downtown, Los Angeles, 90015
            </span>
            {/* <div className="verify-wrapper">
              <img className="bbb" src={bbb} />
              <img className="bbb" src={everify} />
              <img className="bbb" src={ucedc} />
            </div> */}
          </div>
          <div className="footer-right-div ">
            <h1 className="footer-title-2">Ask Us a Question:</h1>
            <form className="footer-form" onSubmit={handleSubmit}>
              <div
                className="qf-message-sbmtn-div"
                style={{ display: displayMessage }}
              >
                <div className="qf-message-wrapper">
                  <img src={whiteTick} className="qf-message-whitetick" />
                  <h3 className="qf-message-h3">
                    Your request has been seccessfully sent!
                    <br /> We'll get in touch with you soon!
                  </h3>
                </div>
              </div>
              <input
                placeholder="Full Name:"
                className="footer-grid-item"
                type="text"
                id="fullName"
                name="fullName"
                required
                onChange={(e) => setFullName(e.target.value)}
                value={fullName}
              />
              <input
                type="text"
                className="footer-grid-item"
                placeholder="Phone:"
                id="phone"
                name="phone"
                required
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
              />

              <input
                type="text"
                placeholder="Email:"
                className="footer-grid-item"
                id="email"
                name="email"
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />

              <input
                type="text"
                placeholder="Title:"
                className="footer-grid-item"
                id="title"
                name="title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
              />

              <textarea
                type="text"
                placeholder="Message:"
                className="footer-grid-item footer-grid-item-5"
                id="message"
                name="message"
                required
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
              <button
                className="btn qf-btn"
                type="submit"
                // onClick={() => {
                //   if (displayMessage == "none") {
                //     setDisplayMessage("flex");
                //   } else {
                //     setDisplayMessage("none");
                //   }
                // }}
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </section>
      <section className="footer-abs-bottom-section">
        <p className="footer-copyright">
          STARS'N'STRIPES MOVERS CO. 2022-2023
          <br />ⓒ All rights reserved.
        </p>
      </section>
    </>
  );
}

export default Footer;
