import React from "react";
import "./hero.scss";

function Hero() {
  return (
    <section className="hero-section">
      <div className="another-div">
        <div className="left-div" />
        <div className="hero-1440-container">
          <div className="hero-title">
            <p className="hero-title-part">
              Get your best
              <br />
            </p>
            <p className="hero-title-part">moving journey</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
