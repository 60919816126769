import React from "react";
import "./second-segment.scss";
import ThirdSection from "../../components/6-ThirdSection/ThirdSection";
import Form from "../../components/6-Form/Form";
import Projects from "../../components/7-Projects/Projects";
import Abs from "./Abs";

const back = require("./back.png");

function SecondSegment({ id, handleClickScroll }) {
  return (
    <>
      <section className="second-segment">
        <div className="second-segment-background">
          <img className="second-segment-background-img" src={back} />
        </div>
        <ThirdSection handleClickScroll={handleClickScroll} />
        <Form id={id} />
        <Projects />
      </section>
    </>
  );
}

export default SecondSegment;
