import React from "react";
import "./third-section.scss";
const movers2 = require("./2.png");
const movers3 = require("./3.png");
const movers4 = require("./4.png");

function ThirdSection({ handleClickScroll }) {
  return (
    <section className="section third-section">
      <div className="ts-abs-div"></div>
      <div className="ts-div container-1320">
        <h1 className="ts-title">
          PRICES'N'
          <br />
          <span className="ts-title-option">DISCOUNTS:</span>
        </h1>
        <div className="ts-grid-container">
          <div className="ts-grid-wrapper">
            <div className="ts-grid-item">
              <div className="ts-grid-img-back">
                <img className="ts-grid-img" src={movers2} />
              </div>
              <h2 className="ts-grid-item-descr">Two movers:</h2>
              <h2 className="ts-grid-item-price">94.99</h2>
              <h2 className="ts-grid-item-price-overlaid">
                &nbsp;$ 119.99&nbsp;
              </h2>
              <h3 className="ts-grid-item-li">✓&ensp;Two men and a truck</h3>{" "}
              <h3 className="ts-grid-item-li">
                ✓&ensp;Gas fee is for real consumption
              </h3>
              <h3 className="ts-grid-item-li">
                ✓&ensp; Free for use wardrobes!
              </h3>
              <h3 className="ts-grid-item-li">
                ✓&ensp;Gas fee is for real consumption
              </h3>
              <h3 className="ts-grid-item-li">&ensp;(Boxes not included)</h3>
              <button className="btn" onClick={handleClickScroll}>
                GET SERVICE
              </button>
            </div>
            <div className="ts-grid-item">
              <div className="ts-grid-img-back">
                <img className="ts-grid-img" src={movers3} />
              </div>
              <h2 className="ts-grid-item-descr">Three movers:</h2>
              <h2 className="ts-grid-item-price">114.99</h2>
              <h2 className="ts-grid-item-price-overlaid">
                &nbsp;$ 144.99&nbsp;
              </h2>
              <h3 className="ts-grid-item-li">✓&ensp;Three men and a truck</h3>{" "}
              <h3 className="ts-grid-item-li">
                ✓&ensp;Gas fee is for real consumption
              </h3>
              <h3 className="ts-grid-item-li">
                ✓&ensp; Free for use wardrobes!
              </h3>
              <h3 className="ts-grid-item-li">
                ✓&ensp;Gas fee is for real consumption
              </h3>
              <h3 className="ts-grid-item-li">&ensp;(Boxes not included)</h3>
              <button className="btn" onClick={handleClickScroll}>
                GET SERVICE
              </button>
            </div>
            <div className="ts-grid-item">
              <div className="ts-grid-img-back">
                <img className="ts-grid-img" src={movers4} />
              </div>
              <h2 className="ts-grid-item-descr">Four movers:</h2>
              <h2 className="ts-grid-item-price">139.99</h2>
              <h2 className="ts-grid-item-price-overlaid">
                &nbsp;$ 164.99&nbsp;
              </h2>
              <h3 className="ts-grid-item-li">✓&ensp;Four men and a truck</h3>{" "}
              <h3 className="ts-grid-item-li">
                ✓&ensp;Gas fee is for real consumption
              </h3>
              <h3 className="ts-grid-item-li">
                ✓&ensp; Free for use wardrobes!
              </h3>
              <h3 className="ts-grid-item-li">
                ✓&ensp;Gas fee is for real consumption
              </h3>
              <h3 className="ts-grid-item-li">&ensp;(Boxes not included)</h3>
              <button className="btn" onClick={handleClickScroll}>
                GET SERVICE
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ThirdSection;
