import React, { useState, useEffect } from "react";
import axios from "axios";

import Form from "../6-Form/Form";
import "./modal1.scss";

const modalBack = require("./modal-back.png");
const x = require("./x.png");

const Modal = ({ open, closeModal }) => {
  const [displayMessage, setDisplayMessage] = useState("none");

  useEffect(() => {}, [displayMessage]);

  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [rooms, setRooms] = useState("");
  const [crew, setCrew] = useState("");
  const [date, setDate] = useState("");
  const [zipFrom, setZipFrom] = useState("");
  const [zipTo, setZipTo] = useState("");
  const [details, setDetails] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    let tgMessage = `<b>New request: </b>
    full name: ${fullName}
    email: ${email}
    phone: ${phone}
    rooms: ${rooms}
    date: ${date}
    zipFrom: ${zipFrom}
    zipTo: ${zipTo}
    details: ${details}
    title: ${title}
    message: ${message}
    `;
    axios
      .post(process.env.REACT_APP_URI_API, {
        chat_id: process.env.REACT_APP_CHAT_ID,
        parse_mode: "html",
        text: tgMessage,
      })
      .then((res) => {
        if (displayMessage == "none") {
          setDisplayMessage("flex");
        } else {
          setDisplayMessage("none");
        }
        // console.log("DONE");
        setFullName("");
        setPhone("");
        setEmail("");
        setRooms("");
        setDate("");
        setCrew("");
        setZipFrom("");
        setZipTo("");
        setTitle("");
        setMessage("");
        setDetails("");
      })
      .catch((err) => {
        alert("Something went wrong!");
      });
  };
  if (!open) return null;
  return (
    <section className="modal-section">
      <div
        onClick={closeModal}
        className="modal-container"
        // style={{ zIndex: "999" }}
      >
        <div className="modal-container-wrapper">
          <img
            src={modalBack}
            className="modal-back"
            style={{ overflow: "hidden", width: "auto", height: "auto " }}
          />

          <h1 className="modal-title" style={{ display: displayMessage }}>
            Thank you for your request!
            <br />
            We will call you back asap!
          </h1>
          <form
            onSubmit={handleSubmit}
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="modal-container"
            style={
              displayMessage === "none"
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            <h1 className="modal-title">We'll Call You Back in Minutes!</h1>

            {/*  */}
            <div className="modal-grid-wrapper">
              {/* <div className="c2">
                <img src={formBack} className="form-back" />
                <img src={tick} className="tick" />
                <h3 className="form-thanks">
                  Thank you for your application!
                  <br /> Your request has been recieved! <br />
                  We will get in touch with you very soon!
                </h3>
              </div> */}
              Your Full Name:
              <input
                type="text"
                className="modal-input"
                placeholder="John Smith"
                id="fullName"
                name="fullName"
                onChange={(e) => setFullName(e.target.value)}
                value={fullName}
              />
              Your Phone Number:
              <input
                type="text"
                className="modal-input"
                placeholder="800 123 4567"
                id="phone"
                name="phone"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
              />
              <button type="submit" className="modal-btn btn">
                Submit
              </button>
            </div>
            {/*  */}
          </form>
          <div className="modal-x-wrapper">
            <img src={x} className="modal-x" onClick={closeModal} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Modal;
