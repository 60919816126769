import React from "react";
import "./second-segment.scss";

const back = require("./back.png");

const Abs = () => {
  return (
    <div className="second-segment-background">
      <img className="second-segment-background-img" src={back} />
    </div>
  );
};

export default Abs;
