import React from "react";
import "./second-section.scss";
const box = require("./box.png");
const stripes = require("./stripes.png");
function SecondSection() {
  return (
    <section className="section second-section">
      {/* <div className="stripes">
        <img className="ss-box" src={stripes} />
      </div> */}
      <div className="btm-abs-div" />
      {/* <div className="btm-abs-div2 outline"></div> */}
      <div className="container-1320 ss-container">
        <div className="ss-right-abs-div" />
        <img className="ss-box" src={box} />
        <h1 className="ss-title">
          Choose the right
          <br /> movers for yourself.
        </h1>
        <p className="ss-p">
          We offer our clients excellent rates for relocation, however there are
          requirements that are worth mentioning at the very beginning:
        </p>

        <div className="ss-grid-wrapper">
          <div className="ss-grid-item">
            <h3 className="ss-grid-item-title">Specs' N' Reqs:</h3>
            <p className="ss-grid-item-p">
              {" "}
              - We are hourly rated only(No flat rates).
              <br /> - 3 hours minimum is required. <br />- Gas fee for real
              consumption (10miles/galon * 5.5$).
              <br />
              - The Double Drive Time Law (DDT) is applicable.
              <br /> - Heavy item fee for one-heavy-non-separable-item could
              apply(225lb+).
              <br /> - That's it. No other fees.
            </p>
          </div>
          <div className="ss-grid-item">
            <h3 className="ss-grid-item-title">Supplies:</h3>
            <p className="ss-grid-item-p">
              {" "}
              - Truck 16’ - 26’ depends on size of the move. (We'll recomend you
              which size will fit the best for your needs).
              <br /> - All teams are supplied with all tools necessary
              (screwdrivers, wretches, hexagons, etc.).
              <br /> - Wardrobes, blankets, shrink wraps, tapes for use - free
              of charge.
              <br /> - Boxes for sale: LG - 5$, MD - 4$, SM -3$ (of course, it
              could be discounts for bulky moves! Let us know!).
            </p>
          </div>
          <div className="ss-grid-item">
            <h3 className="ss-grid-item-title">Crew:</h3>
            <p className="ss-grid-item-p">
              - Minimum 1 year experience requirement for local moves.
              <br /> 3 years for long distance.
              <br /> - Drug and alcohol tested.
              <br /> - Mostly, kind, fast and hardworking(feedback on the work
              of our movers is very welcome!)
            </p>
          </div>
          <div className="ss-grid-item">
            <h3 className="ss-grid-item-title">Confirmation steps:</h3>
            <p className="ss-grid-item-p">
              - You submit the form below.
              <br /> - We contact you to clear details(if necessary) and confirm
              booking details, such as date, time, number of crew, etc.
              <br /> - Second(and final) confirmation on the day of the move.
            </p>
          </div>
          {/* <div className="ss-grid-item">
            <h3 className="ss-grid-item-title">Supplies:</h3>
          </div>
          <div className="ss-grid-item">
            <h3 className="ss-grid-item-title">Supplies:</h3>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default SecondSection;
