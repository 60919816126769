import React, { useState, useEffect } from "react";
import axios from "axios";

import "./quick-form.scss";
const whiteTick = require("./white-tick.png");

function QuickForm() {
  const [displayMessage, setDisplayMessage] = useState("none");

  useEffect(() => {}, [displayMessage]);

  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [rooms, setRooms] = useState("");
  const [crew, setCrew] = useState("");
  const [date, setDate] = useState("");
  const [zipFrom, setZipFrom] = useState("");
  const [zipTo, setZipTo] = useState("");
  const [details, setDetails] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    let tgMessage = `<b>New request: </b>
    full name: ${fullName}
    email: ${email}
    phone: ${phone}
    rooms: ${rooms}
    date: ${date}
    zipFrom: ${zipFrom}
    zipTo: ${zipTo}
    details: ${details}
    title: ${title}
    message: ${message}
    `;
    axios
      .post(process.env.REACT_APP_URI_API, {
        chat_id: process.env.REACT_APP_CHAT_ID,
        parse_mode: "html",
        text: tgMessage,
      })
      .then((res) => {
        if (displayMessage == "none") {
          setDisplayMessage("flex");
        } else {
          setDisplayMessage("none");
        }
        // console.log("DONE");
        setFullName("");
        setPhone("");
        setEmail("");
        setRooms("");
        setDate("");
        setCrew("");
        setZipFrom("");
        setZipTo("");
        setTitle("");
        setMessage("");
        setDetails("");
      })
      .catch((err) => {
        alert("Something went wrong!");
      });
  };
  return (
    <section className="section qf-section">
      <div className="container-1320 qf-div">
        <div className="qf-left-div">
          <h3 className="qf-title">
            Quick
            <br /> Form >>
          </h3>
        </div>
        <div className="qf-right-div">
          <form className="qf-form" onSubmit={handleSubmit}>
            <div
              className="qf-message-sbmtn-div"
              style={{ display: displayMessage }}
            >
              <div className="qf-message-wrapper">
                <img src={whiteTick} className="qf-message-whitetick" />
                <h3 className="qf-message-h3">
                  Your request has been seccessfully sent!
                  <br /> We'll get in touch with you soon!
                </h3>
              </div>
            </div>
            {/* <p
                className="qf-message-p"
                onClick={() => setDisplayMessage("none")}
              >
                Go back to the form
              </p> */}
            <input
              className="qf-form-element"
              type="text"
              id="fullName"
              name="fullName"
              placeholder="Full Name"
              onChange={(e) => setFullName(e.target.value)}
              value={fullName}
              required
            />
            <input
              className="qf-form-element"
              placeholder="Phone Number:"
              type="text"
              id="phone"
              name="phone"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              required
            />
            <input
              className="qf-form-element"
              type="text"
              placeholder="Moving Date:"
              id="date"
              name="date"
              onChange={(e) => setDate(e.target.value)}
              value={date}
              required
            />
            <button
              className="qf-form-element btn qf-btn"
              type="submit"
              // onClick={() => {
              //   console.log(displayMessage);
              //   if (displayMessage == "none") {
              //     setDisplayMessage("flex");
              //   } else {
              //     setDisplayMessage("none");
              //   }
              // }}
            >
              <i className="flaticon-enter"></i> Submit Now
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default QuickForm;
