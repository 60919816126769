import React, { useState, useEffect } from "react";
import axios from "axios";
import "./form.scss";
const tick = require("./tick.png");
const formBack = require("./form-back.png");

function Form({ id }) {
  const [displayMessage, setDisplayMessage] = useState("none");

  useEffect(() => {}, [displayMessage]);

  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [rooms, setRooms] = useState("");
  const [crew, setCrew] = useState("");
  const [date, setDate] = useState("");
  const [zipFrom, setZipFrom] = useState("");
  const [zipTo, setZipTo] = useState("");
  const [details, setDetails] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    let tgMessage = `<b>New request: </b>
    full name: ${fullName}
    email: ${email}
    phone: ${phone}
    rooms: ${rooms}
    date: ${date}
    zipFrom: ${zipFrom}
    zipTo: ${zipTo}
    details: ${details}
    title: ${title}
    message: ${message}
    `;
    axios
      .post(process.env.REACT_APP_URI_API, {
        chat_id: process.env.REACT_APP_CHAT_ID,
        parse_mode: "html",
        text: tgMessage,
      })
      .then((res) => {
        if (displayMessage == "none") {
          setDisplayMessage("flex");
        } else {
          setDisplayMessage("none");
        }
        // console.log("DONE");
        setFullName("");
        setPhone("");
        setEmail("");
        setRooms("");
        setDate("");
        setCrew("");
        setZipFrom("");
        setZipTo("");
        setTitle("");
        setMessage("");
        setDetails("");
      })
      .catch((err) => {
        alert("Something went wrong!");
      });
  };
  return (
    <section id={id} className="section form-section">
      <div className="container-1320">
        <form className="form-grid" onSubmit={handleSubmit}>
          <div className="c2" style={{ display: displayMessage }}>
            <img src={formBack} className="form-back" />

            <img src={tick} className="tick" />
            <h3 className="form-thanks">
              Thank you for your application!
              <br /> Your request has been recieved! <br />
              We will get in touch with you very soon!
            </h3>
            <a
              className="form-back-link"
              onClick={() => setDisplayMessage("none")}
            >
              Get back to the form >>
            </a>
          </div>
          <h3 className="form-title">
            READY FOR THE MOVE?
            <option className="form-title-option">BOOK IT!</option>
          </h3>
          <div className="form-grid-left">
            <div className="form-grid-item-wrapper">
              <h5 className="form-grid-item-title">Full Name:</h5>
              <input
                className="form-grid-item"
                placeholder="Full Name:"
                type="text"
                id="fullName"
                name="fullName"
                required
                onChange={(e) => setFullName(e.target.value)}
                value={fullName}
              />
            </div>
            <div className="form-grid-item-wrapper">
              <h5 className="form-grid-item-title">Phone Number:</h5>
              <input
                className="form-grid-item"
                placeholder="Phone Number:"
                type="text"
                id="phone"
                name="phone"
                required
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
              />
            </div>
            {/* <div className="form-grid-item-wrapper">
              <h5 className="form-grid-item-title">Email:</h5>
              <input
                className="form-grid-item"
                placeholder="Your Answer:"
                type="text"
                id="Email"
                name="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div> */}
            <div className="form-grid-item-wrapper">
              <h5 className="form-grid-item-title">Zip of Origin:</h5>
              <input
                className="form-grid-item"
                placeholder="Zip of Origin:"
                type="text"
                id="zipFrom"
                name="zipFrom"
                required
                onChange={(e) => setZipFrom(e.target.value)}
                value={zipFrom}
              />
            </div>
            <div className="form-grid-item-wrapper">
              <h5 className="form-grid-item-title">Zip of Destination:</h5>
              <input
                className="form-grid-item"
                placeholder="Zip of Destination:"
                type="text"
                id="zipTo"
                name="zipTo"
                required
                onChange={(e) => setZipTo(e.target.value)}
                value={zipTo}
              />
            </div>
            <div className="form-grid-item-wrapper">
              <h5 className="form-grid-item-title">Prefered Date:</h5>
              <input
                className="form-grid-item"
                placeholder="Prefered Date:"
                type="text"
                id="date"
                name="date"
                required
                onChange={(e) => setDate(e.target.value)}
                value={date}
              />
            </div>
            <div className="form-grid-item-wrapper">
              <h5 className="form-grid-item-title">Number of crew:</h5>
              <input
                className="form-grid-item"
                placeholder="Number of crew:"
                type="text"
                id="secondName"
                name="secondName"
                onChange={(e) => setCrew(e.target.value)}
                value={crew}
              />
              {/* <select
                className="form-grid-item"
                id="rooms"
                name="rooms"
                placeholder="Number of Rooms:"
                onChange={(e) => setRooms(e.target.value)}
              >
                <option value="-">Number of crew:</option>
                <option value="Studio">2 movers</option>
                <option value="1-bd">3 movers</option>
                <option value="2-bd">4 movers</option>
                <option value="3-bd">Other</option>
              </select> */}
            </div>
            <div className="form-grid-item-wrapper">
              <h5 className="form-grid-item-title">Move Size:</h5>
              <input
                className="form-grid-item"
                placeholder="Move Size:"
                type="text"
                id="rooms"
                name="rooms"
                required
                onChange={(e) => setRooms(e.target.value)}
                value={rooms}
              />
              {/* <select
                className="form-grid-item"
                id="rooms"
                name="rooms"
                placeholder="Number of Rooms:"
                onChange={(e) => setRooms(e.target.value)}
              >
                <option value="-">Number of rooms:</option>
                <option value="Studio">Studio</option>
                <option value="1-bd">1-bedroom</option>
                <option value="2-bd">2-bedrooms</option>
                <option value="3-bd">3-bedrooms</option>
                <option value="4-bd">4-bedrooms</option>
                <option value="5+bd">5 or more bedrooms</option>
              </select> */}
            </div>
            <div className="form-grid-item-wrapper form-grid-item-wrapper-last">
              <h5 className="form-grid-item-title">
                Special Requests or Concerns:
              </h5>
              <textarea
                className="form-grid-item form-grid-item-last"
                placeholder="Requests or Concerns:"
                id="rooms"
                name="rooms"
                onChange={(e) => setDetails(e.target.value)}
                value={details}
              />
            </div>
            <button className="btn form-btn" type="submit">
              BOOK IT!
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Form;
