import React, { useState, useEffect } from "react";
import Form from "../6-Form/Form";
import "./modal-new.scss";

const modalBack = require("./modal-back.png");
const x = require("./x.png");

const Modal = ({ open, closeModal }) => {
  const [displayMessage, setDisplayMessage] = useState("none");

  useEffect(() => {}, [displayMessage]);

  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [rooms, setRooms] = useState("");
  const [date, setDate] = useState("");
  const [zipFrom, setZipFrom] = useState("");
  const [zipTo, setZipTo] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const values = {
      fullName,
      firstName,
      secondName,
      phone,
      email,
      rooms,
      date,
      zipFrom,
      zipTo,
      title,
      message,
    };
    fetch("http://localhost:1400", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    })
      .then(() => {
        if (displayMessage == "none") {
          setDisplayMessage("flex");
        } else {
          setDisplayMessage("none");
        }
        // console.log("DONE");
        setFullName("");
        setFirstName("");
        setSecondName("");
        setPhone("");
        setEmail("");
        setRooms("");
        setDate("");
        setZipFrom("");
        setZipTo("");
        setTitle("");
        setMessage("");
      })
      .catch(() => {
        alert("Something went wrong!");
      });
  };

  if (!open) return null;
  return <section className="modal-section"></section>;
};

export default Modal;
