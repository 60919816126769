import React from "react";
import "./projects.scss";
const img1 = require("./img/project-1.jpg");
const img2 = require("./img/project-2.jpg");
const img3 = require("./img/project-3.jpg");
const img4 = require("./img/project-4.jpg");
const img5 = require("./img/project-5.jpg");
const img6 = require("./img/project-6.jpg");
const img7 = require("./img/project-7.jpg");
const img8 = require("./img/project-8.jpg");
const projectsArray = [
  {
    img: img1,
    location: "Hollywood > Van Nuys, CA",
    descr: "Nice Photo, Guys! The Workflow is in Full Swing!",
  },
  {
    img: img2,
    location: "Brea > San Diego, CA",
    descr: "It were not easy, but we managed! 4bd Condo - Done!",
  },
  {
    img: img3,
    location: "Brentwood > Palm Springs, CA",
    descr: "Moving Heavy Hot Tub to Palm Springs, CA!",
  },
  {
    img: img4,
    location: "Culver City > Huntington Park, CA",
    descr: "Nice and Eazy move on a South Side, LA!",
  },
  {
    img: img5,
    location: "West Hollywood > Glendale, CA",
    descr: "Relocating Typography on a New Location!!",
  },
  {
    img: img6,
    location: "Long Beach > Pasadena, CA",
    descr: "Commercial Tasks is not Easy Tasks! But We Do it All!",
  },
  {
    img: img7,
    location: "Irvine > Santa Ana, CA",
    descr: "Nice Job, Guys! Irvine > Santa Ana, CA. Done!",
  },
  {
    img: img8,
    location: "Santa Ana > San Bernardino, CA",
    descr: "Pleasant Moments With the Client After a Job! Well Done!",
  },
];

function Projects() {
  return (
    <section className="section projects-section">
      {/* <div className="projects-abs-back">
        <img className="projects-back-img" src={back} />
      </div> */}
      <div className="container-1320 projects-div">
        <h1 className="projects-title">
          Thank You For Your Pick!
          <br />
          Your <span className="stars-option">STARS'N</span>
          <span className="stripes-option">'STRIPES</span> Movers!
        </h1>
        <div className="projects-grid-wrapper">
          {projectsArray.map((project) => {
            return (
              <div className="projects-grid-item-wrapper">
                <div className="projects-grid-item">
                  <img className="projects-grid-item-img" src={project.img} />
                </div>
                <div className="projects-grid-item-bottom">
                  <p className="projects-grid-item-location">
                    {project.location}
                  </p>
                  <p className="projects-grid-item-descr">{project.descr}</p>
                </div>
              </div>
            );
          })}
          {/* <div className="projects-grid-item outline">
            <div className="projects-grid-item-bottom"></div>
          </div> */}
          {/* <div className="projects-grid-item outline">
            <div className="projects-grid-item-bottom"></div>
          </div>
          <div className="projects-grid-item outline">
            <div className="projects-grid-item-bottom"></div>
          </div>
          <div className="projects-grid-item outline">
            <div className="projects-grid-item-bottom"></div>
          </div>
          <div className="projects-grid-item outline">
            <div className="projects-grid-item-bottom"></div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Projects;
