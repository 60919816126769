import React from "react";
import "./first-section.scss";
const fsImg1 = require("./fs-img1.jpg");
function FirstSection({ id }) {
  return (
    <section id={id} className="section first-section">
      <div className="fs1-div container-1320">
        <div className="square-abs"></div>
        <div className="fs-text-div">
          <div className="fs-img-div">
            <img className="fs-img1" src={fsImg1} />
          </div>
          <h1 className="fs-title">Are you ready for the move?</h1>
          <p className="fs-p">
            &ensp;&ensp;Have you already prepared practically and mentally for
            the upcoming move? Our task, as for moving company, is to bring what
            was started to its logical conclusion: quickly, efficiently and at
            no extra cost to your budget!
            <br />
            &ensp;&ensp;In turn, we offer you a profitable solution for your
            residential relocation! We have been specializing in moving for many
            years and our proven strategy allows us to offer excellent
            conditions for our clients to move quickly, calmly and at no extra
            expences. <br /> &ensp;&ensp;Read more about our conditions and you
            will be pleasantly surprised, because our balance in terms of
            quality and price is always and without alternative inclined towards
            inexpensive and exceptionally high-quality and efficient moving!
          </p>
        </div>
      </div>
    </section>
  );
}

export default FirstSection;
