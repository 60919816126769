import React from "react";
import FirstSection from "../components/4-FirstSection/FirstSection";
import SecondSection from "../components/5-SecondSection/SecondSection";
// import ThirdSection from "../components/6-ThirdSection/ThirdSection";
import Footer from "../components/8-Footer/Footer";
// import Form from "../components/6-Form/Form";
import Hero from "../components/2-Hero/Hero";
import Navbar from "../components/1-Navbar/Navbar";
// import Projects from "../components/7-Projects/Projects";
import QuickForm from "../components/3-QuickForm/QuickForm";
import SecondSegment from "../segments/SecondSegment/SecondSegment";

const Home = () => {
  const handleClickScroll = () => {
    const element = document.getElementById("form");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Navbar />
      <Hero />
      <QuickForm />
      <FirstSection id="First Section" />
      <SecondSection />
      {/* <ThirdSection />
      <Form />
      <Projects /> */}
      <SecondSegment handleClickScroll={handleClickScroll} id="form" />
      <Footer />
    </>
  );
};

export default Home;
