import React, { useState } from "react";
import "./navbar.scss";
import ModalNew from "./ModalNew";
import Modal1 from "./Modal1";
const logo = require("./logo.png");
function Navbar() {
  const [openModal, setOpenModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Modal1 open={openModal} closeModal={() => setOpenModal(false)} />
      {/* <Modal open={openModal} closeModal={() => setOpenModal(false)} /> */}
      <section className="section navbar-section">
        <div className="navbar-container  container-1320">
          <div className="navbar-left-div">
            <img className="logo" src={logo} />
          </div>
          <div className="navbar-center-div"></div>
          <div className="navbar-right-div">
            <button
              className="btn navbar-btn"
              onClick={() => setOpenModal(true)}
            >
              Request Callback
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default Navbar;
